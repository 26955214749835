<template>
  <div class="emergencySuppliesTable" :style="{height:height}">
    <el-table
        :show-header="false"
        border
        stripe
        v-loading="loading"
        height="100%"
        :data="tableData"
        @row-click="rowClick"
    >
      <el-table-column>
        <template slot-scope="scope">
          <emergency-supplies-cell :data-info="scope.row"></emergency-supplies-cell>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import EmergencySuppliesCell from '@/views/emergencySupplies/emergencySuppliesCell'

export default {
  name: 'emergencySuppliesTable',
  components: { EmergencySuppliesCell },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'calc(100% - 40px)'
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    rowClick: function(item) {
      this.$emit('rowClick', item)
    }
  }
}
</script>

<style lang="scss" scoped>

.emergencySuppliesTable {

}

</style>
