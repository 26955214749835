<template>
  <div class="emergencySupplies">
    <h-stack class="checkAsset" align-items="flex-start">
      <div class="checkAssetChooseBGView">
        <div class="checkAssetChoose">
          <el-button style="height: 30px" size="mini" type="primary" @click="showTree('org')"
                     :loading="loadOrg"
          >科室
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start"
                   class="checkAssetChooseSelect"
          >
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectOrgList" @click="showSelectView('org')">
              {{ item.name }}
            </div>
          </h-stack>
        </div>
      </div>
    </h-stack>

    <emergency-supplies-table
        :show-header="false"
        border
        stripe
        :loading="loading"
        :table-data="tableData" @rowClick="rowClick">
    </emergency-supplies-table>


    <vue-tree
        ref="vueTree"
        @dialogClose="dialogClose"
        @dialogSure="dialogSure"
        :expanded-keys="defaultCheckedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :dialog-title="dialogType=='' ? '' : '科室'"
        :data-list="dataList"
        :dialog-visible="dialogVisible"
    ></vue-tree>


    <el-drawer
        :modal="false"
        size="calc(100% - 50px)"
        :visible.sync="showDetail"
        :with-header="false"
        direction="btt">
      <emergency-supplies-detail @rowClickDetail="rowClickDetail" ref="emergencySuppliesDetail" :data-info="detail"></emergency-supplies-detail>
    </el-drawer>

    <el-drawer
        :modal="false"
        size="calc(100% - 50px)"
        :visible.sync="showDeviceDetail"
        :with-header="false"
        :destroy-on-close='true'
        direction="btt">
      <search-device-detail :device-detail="device"></search-device-detail>
    </el-drawer>

  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import VStack from '@/components/vStack/vStack'
import VueTree from '@/components/tree/vueTree'
import emergencySuppliesMixin from '@/views/emergencySupplies/mixin/emergencySuppliesMixin'
import EmergencySuppliesTable from '@/views/emergencySupplies/emergencySuppliesTable'
import EmergencySuppliesDetail from '@/views/emergencySupplies/emergencySuppliesDetail/emergencySuppliesDetail'
import SearchDeviceDetail from '@/views/searchDevice/searchDeviceDetail/searchDeviceDetail'

export default {
  name: 'emergencySupplies',
  components: { SearchDeviceDetail, EmergencySuppliesDetail, EmergencySuppliesTable, VueTree, VStack, HStack },
  mixins: [emergencySuppliesMixin],
  computed: {
    dataList: function() {
      let list = []
      if (this.dialogType == '') {
      } else if (this.dialogType == 'org') {
        list = this.orgList
      }
      return list
    },
    defaultCheckedKeys: function() {
      let list = []
      let selectList = []
      if (this.dialogType == '') {
      } else if (this.dialogType == 'org') {
        selectList = this.selectOrgList
      }
      for (let i = 0; i < selectList.length; i++) {
        let asset = selectList[i]
        if (list.indexOf(asset.id) >= 0) {
          continue
        }
        list.push(asset.id)
      }
      return list
    }
  },
  data() {
    return {
      dialogType: '',
      loadOrg: false,
      selectOrgList: [],
      orgList: [], // 科室
      dialogVisible: false,
      loading: false,
      tableData:[],
      showDetail:false,
      showDeviceDetail:false,
      detail:{},
      device:{},
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllData()
    })
  },
  methods: {
    rowClickDetail:function(item) {
      this.showDeviceDetail = true;
      this.device = item;
    },
    rowClick:function(item) {
      this.showDetail = true;
      this.detail = item;
      this.$nextTick(()=>{
        if (typeof this.$refs['emergencySuppliesDetail'] == 'undefined' || this.$refs['emergencySuppliesDetail'] == null) {
          return;
        }
        this.$refs['emergencySuppliesDetail'].loadTableDataInfo(true, true);
      })

    },
    dialogClose: function(res) {
      this.dialogVisible = res.dialogVisible
    },
    dialogSure: function(res) {
      this.dialogVisible = res.dialogVisible;
      this.selectOrgList = res.nodes;
      this.loading = true;
      this.getEmergencySupplies().then(res => {
        this.loading = false;
        this.tableData = res.list;
      })
    },
    showSelectView: function(type) {
      if (typeof this.$refs.vueTree == 'undefined') {
        return
      }
      this.dialogVisible = true
      this.$refs.vueTree.showAllView()
      this.$nextTick(() => {
        this.$refs.vueTree.showSelectView()
      })
    },
    showTree: function(type) {
      if (typeof this.$refs.vueTree == 'undefined') {
        return
      }
      this.dialogType = type
      this.dialogVisible = true
      this.$refs.vueTree.showAllView()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.emergencySupplies {
  width: 100%;
  height: 100%;

  .checkAsset {
    width: 100%;
    background-color: white;


    .checkAssetTitle {
      text-align: center;
      width: 70px;
      height: 30px;
      line-height: 30px;
      float: left;
      font-size: 14px;
      color: white;
      border-radius: 5px;
      background-color: $color_primary;
      border: none;
    }

    .title {
      width: 70px;
      height: 30px;
    }

    .checkAssetChooseBGView {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;

      .checkAssetDatePicker {
        width: 90%;
      }

      .checkAssetChoose {
        float: left;
        width: calc(100% - 20px);
        transform: translate(10px, 0px);
        margin-top: 5px;


        .checkAssetChooseSelect {
          float: right;
          width: calc(100% - 95px);
          margin-left: 5px;
          overflow: scroll;
          max-height: 30px;


          .checkAssetChooseSelectItem {
            padding-left: 5px;
            padding-right: 5px;
            word-wrap: break-word;
            margin-left: 5px;
            font-size: 13px;
            color: $color_primary;
            line-height: 28px;
            min-height: 28px;
            border-radius: 5px;
            margin-bottom: 2px;
          }
        }
      }
    }

    .checkAssetAction {
      width: 60px;
      height: 60px;
      margin-right: 5px;
    }
  }


}

</style>
