<template>
  <div class="emergencySuppliesDetail">
    <el-table
        :show-header="false"
        border
        stripe
        v-loading="loading"
        height="calc(100% - 40px)"
        :data="tableData"
        @row-click="rowClick"
    >
      <el-table-column>
        <template slot-scope="scope">
          <emergency-supplies-detail-cell :data-info="scope.row"></emergency-supplies-detail-cell>
        </template>
      </el-table-column>
    </el-table>
    <h-stack style="height: 40px;background-color: white">
      <el-pagination
          @current-change="currentChange"
          :page-size="pageSize"
          :pager-count="5"
          :current-page="page"
          layout="prev, pager, next"
          :total="totalCount"
      >
      </el-pagination>
    </h-stack>
  </div>
</template>

<script>
import EmergencySuppliesDetailCell from '@/views/emergencySupplies/emergencySuppliesDetail/emergencySuppliesDetailCell'
import HStack from '@/components/hStack/hStack'

export default {
  name: 'emergencySuppliesDetail',
  components: { HStack, EmergencySuppliesDetailCell },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 50,
      totalCount: 0
    }
  },
  mounted() {
    this.$nextTick(()=>{

    })
  },
  methods: {
    rowClick: function(item) {
      this.$emit('rowClickDetail', item)
    },
    reset: function() {
      this.page = 1
      this.pageSize = 30
    },
    currentChange: function(index) {
      this.page = index
      this.loadTableDataInfo(false, true)
    },
    loadTableDataInfo: function(reset, loading) {
      if (reset) {
        this.reset()
      }
      if (loading) {
        this.loadTableData = true
      }

      let loadData = () => {
        let tenantId = ''
        if (this.$valueIsExist(this.dataInfo, 'tenantId')) {
          tenantId = this.dataInfo['tenantId']
        }

        let id = ''
        if (this.$valueIsExist(this.dataInfo, 'id')) {
          id = this.dataInfo['id']
        }
        this.$api.assetModule
            .getEmergencySuppliesDetail({
              id: id,
              page: this.page,
              pageSize: this.pageSize,
              status: '',
              tenantId: tenantId
            }).then(res => {
          if (res.code == 200) {
            this.tableData = res.data
            this.totalCount = res.totalCount
          }
          this.loadTableData = false

        }).catch(e => {
          this.loadTableData = false
        })
      }

      loadData()

    }
  }
}
</script>

<style lang="scss" scoped>

.emergencySuppliesDetail {
  width: 100%;
  height: 100%;

}

</style>
