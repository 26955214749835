<template>
<div class="emergencySuppliesCell">
  <div>
    <div class="title">物资名称</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'name') == false ? '-' : dataInfo['name'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">单位</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'unit') == false ? '-' : dataInfo['unit'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">定点收治传染<p>病专科医院</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'professionalHos') == false ? '-' : dataInfo['professionalHos'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">定点收治<p>三级甲等医院</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'thirdClassHos') == false ? '-' : dataInfo['thirdClassHos'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">其它三级甲等<p>综合医院</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'otherThirdClassHos') == false ? '-' : dataInfo['otherThirdClassHos'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color: red">我院保有量<p>A</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'inventory') == false ? '-' : dataInfo['inventory'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color: red">在院备机量<p>B</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'reserve') == false ? '-' : dataInfo['reserve'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color: #67c23a">名义总可用量<p>N=A+B</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'total') == false ? '-' : dataInfo['total'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color: #67c23a">实际总可用量<p>R=U+S+I</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'actualTotal') == false ? '-' : dataInfo['actualTotal'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color:#27aeb8;">使用中<p>U</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'useNum') == false ? '-' : dataInfo['useNum'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color:#27aeb8;">待机中<p>S</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'closeNum') == false ? '-' : dataInfo['closeNum'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color: #e68b35">仓储中<p>I</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'storage') == false ? '-' : dataInfo['storage'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color: #e68b35">维修中<p>F</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'repair') == false ? '-' : dataInfo['repair'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title" style="color:#27aeb8;">可调拨数量<p>D=S+I-F</p></div>
    <div class="content">{{ $valueIsExist(dataInfo, 'canUseTotal') == false ? '-' : dataInfo['canUseTotal'] }}</div>
    <div style="clear: both"></div>
  </div>
</div>
</template>

<script>
export default {
  name: 'emergencySuppliesCell',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>

.emergencySuppliesCell {

  .title {
    width: 100px;
    text-align: right;
    //height: 30px;
    line-height: 25px;
    font-size: 13px;
    float: left;
  }

  .content {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 120px);
    text-align: right;
    line-height: 25px;
    font-size: 13px;
    text-align: left;
    float: right;
    word-break: break-all;
  }
}


</style>
