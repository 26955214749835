<template>
<div class="emergencySuppliesDetailCell">
  <div>
    <div class="title">物资名称</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'name') == false ? '-' : dataInfo['name'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">{{$VUEAPPDEVICECODE()}}</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'code') == false ? '-' : dataInfo['code'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">科室</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'tenantName') == false ? '-' : dataInfo['tenantName'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">设备分类</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'categoryName') == false ? '-' : dataInfo['categoryName'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">品牌</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'brandName') == false ? '-' : dataInfo['brandName'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">型号</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'modelName') == false ? '-' : dataInfo['modelName'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">状态</div>
    <div  class="content">{{ $valueIsExist(dataInfo, 'status') == false ? '待机中' : (dataInfo['status'] == 1 ? '待机中' : '使用中') }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">位置</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'place') == false ? '-' : dataInfo['place'] }}</div>
    <div style="clear: both"></div>
  </div>
</div>
</template>

<script>
export default {
  name: 'emergencySuppliesDetailCell',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
}
</script>

<style lang="scss" scoped>


.emergencySuppliesDetailCell {

.title {
  width: 100px;
  text-align: right;
//height: 30px;
  line-height: 25px;
  font-size: 13px;
  float: left;
}

.content {
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 120px);
  text-align: right;
  line-height: 25px;
  font-size: 13px;
  text-align: left;
  float: right;
  word-break: break-all;
}
}


</style>
