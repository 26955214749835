let emergencySuppliesMixin = {
  methods: {
    getAllData: function() {
      this.loadOrg = true;
      this.loading = true;
      Promise.all([this.getOrg()]).then(res => {

        let orgRes = res[0]
        this.orgList = orgRes.list
        this.selectOrgList = orgRes.selectList
        this.loadOrg = false
        this.getEmergencySupplies().then(res => {
          this.loading = false;
          this.tableData = res.list;
        })
      })
    },
    // 获取子机构科室
    getOrg: function() {
      return new Promise(resolve => {
        this.orgList = []
        this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
          let jsonStr = JSON.stringify(res.data)
          jsonStr = jsonStr.replace(/title/g, 'name')
          jsonStr = jsonStr.replace(/child/g, 'children')

          let list = []
          let selectList = []

          try {
            list = JSON.parse(jsonStr)
            let userInfo = this.$store.getters.userInfo
            let tenantId = userInfo.tenantId
            for (let i = 0; i < list.length; i++) {
              let org = list[i]
              if (org.id == tenantId) {
                selectList.push(org)
                if (i == 0) {
                  org.id = ''
                }
                break
              }
            }
          } catch (e) {

          }
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    getEmergencySupplies: function() {
      return new Promise(resolve => {
        let tenantId = ''
        for (let i = 0; i < this.selectOrgList.length; i++) {
          tenantId = tenantId + (tenantId == '' ? '' : ',') + this.selectOrgList[i].id
        }
        this.$api.assetModule
          .getEmergencySupplies({
            isTenantIdChild: 1,
            tenantId: tenantId
          }).then(res => {

          let list = []
          if (typeof res['data'] != 'undefined' && res['data'] != null) {
            list = res['data'];
          }
          resolve({ list: list })
        }).catch(e => {
          resolve({ list: [] })
        })
      })

    }
  }
}
export default emergencySuppliesMixin
